html {
  --section-background-color: linear-gradient(to bottom left,
      rgba(17, 16, 16, 0.582),
      rgba(12, 8, 24, 0.904));

  --image-gradient: linear-gradient(to bottom left,
      rgba(17, 16, 16, 0.678),
      rgba(12, 10, 22, 0.863));

  --imp-text-color: #76f070;
}


.home-typer {
  padding: 4vh 0 0 0 !important;
  font-family: 'Goldman', cursive;
}

.home-typer h1 {
  font-weight: 500 !important;
  font-size: clamp(2vw, 6vw, 50px) !important;
  text-align: center !important;

}

.about-title {
  font-family: 'Meow Script', cursive;
  font-size: clamp(3vw, 7vw, 4vw) !important;
  padding-left: clamp(2vw, 4vw, 20px);
  margin: 0;
  margin-bottom: clamp(-2vw, 0vw, -10px);
}

.title {
  font-family: "Goldman", cursive;
  font-size: clamp(4vw, 15vw, 13vw);
  text-align: center;

  margin: 0;
  color: var(--imp-text-color) !important;

}

.purple {
  color: var(--imp-text-color) !important;
}

.project-heading {
  color: white !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 12vw !important;
  padding-bottom: 15vw !important;
  background-color: #000000;
  // background-image: var(--section-background-color) !important;
  color: white !important;
  min-width: 200px;
}

.tech-icons {
  font-size: 8vw !important;
  max-width: 3rem;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(137, 230, 142, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(4, 168, 15, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

.tech-icons svg {
  margin-bottom: 10%;
}


.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(115, 230, 134, 0.883) !important;
}

.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-content {
  justify-content: center;
  padding: 10px;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}



.App {
  width: 100%;
  height: 100vh;
  color: #8bfc93;
}

.home-section {
  position: relative;
  z-index: -1;
  background-position: top center;
  background-color: #000000;
  // background-image: var(--image-gradient), url(./assets/home-bg.jpg); 
  background-repeat: no-repeat;
  /* padding-bottom: 30px;
  padding-top: 30px; */
}

.home-content {
  padding: clamp(20vh, 40vh, 30vh) 0 0 0;
  color: whitesmoke;
  text-align: left;
}

.welcome {
  font-family: 'Meow Script', cursive;
  font-size: clamp(4vw, 8vw, 60px) !important;
  padding-left: 50px;
  margin: 0;
  margin-bottom: -.5vw;
}

.companyName {
  font-family: "Goldman", cursive;
  font-size: clamp(1vw, 12vw, 75px) !important;
  /* padding-left: 45px; */
  margin: 0;
  text-align: center !important;
  transform: scaleY(2);
}

.home-header {
  justify-content: center !important;
}


.main-name {
  color: #62f85f;
}


#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100vw;
  height: 100vh;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #74df6a !important;
  font-weight: 600 !important;
}

.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #62d670 !important;
}




/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #000000a9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: .5vh 1vw !important;
  font-size: 1.2rem !important;
  background-color: #000000;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #0c7600 !important;
  height: .7vh !important;
  width: 7vw !important;
  margin: 1.5vw 0;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 2vh !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 1 !important;

}

@media (max-width: 767px) {

  .home-img {
    width: clamp(2vw, 80vw, 70vw) !important;
    padding: 45vh 0 0 0;
  }

  .navbar {
    width: 100%;
    padding: 2vh 0 !important;
    font-size: 4.5vw !important;
    background-color: #000000 !important;
    text-align: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

  }

  .tech-icons:hover {
    transform: scale(1.03) !important;
    border: 2.2px solid rgba(115, 230, 134, 0.883) !important;
  }

  .tech-icons {
    margin: 15px !important;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }

  .navbar-nav .nav-item {
    margin: 1vh 0 !important;

  }

  .MobileNavMenu {
    padding: 3vh 0;
    background-color: rgb(0, 0, 0);
  }

  .nav-link {
    padding: 0.7rem 1rem !important;
  }

  .about-img {
    padding: 120px 0 50px 0;
    width: 65vw !important;

  }

  .title {
    font-size: clamp(4vw, 85px, 13vw);

  }

  .about-title {
    font-size: clamp(3vw, 50px, 7vw) !important;

  }

  .project-heading {
    font-size: clamp(3vw, 35px, 6vw) !important;
  }


}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo img {
  height: auto;
  width: 20vw !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}


.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #48f100;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

.fork-btn {
  padding-top: 10px !important;
}

.fork-btn p{
  margin: 0;
  font-size: clamp(0.5vw, 2vw, 1.5vw) !important;
}

.fork-btn-inner {
  line-height: clamp(1vw, 100px, 3vw) !important;
  background-color: #55ce4c5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-color: #ffff !important;

}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #4dd35186 !important;
  border-color: #51d34d86 !important;
}

.fork-btn-inner::after {
  display: none !important;
}

.about-header {
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 50;
  box-sizing: border-box;
}

.about-img {
  align-items: center;
  padding: 3vw 0 5vw 0;
}

.home-img {
  width: clamp(2vw, 40vw, 30vw);
}

@media (min-width: 768px) {


  .navbar {
    padding: clamp(0vw, 1.6vh, 1.5vw) 0 !important;
    background-color: #000000 !important;
    text-align: center;
  }

  .nav-item {
    font-size: clamp(1.5vw, 40px, 1.25vw) !important;
  }


  .aboutTitle {
    font-size: 6vw;
  }

  .home-img {
    padding: 0;
  }

  .home-title {
    justify-content: none !important;
    padding: 4vw 0 0 0;
  }

  .welcome {
    font-size: clamp(3vw, 70px, 4vw) !important;
    padding-left: 50px;
  }

  .companyName {
    font-size: clamp(1vw, 100px, 6vw) !important;
  }

  .home-typer h1 {
    font-size: clamp(1vw, 60px, 4vw) !important;
  }

  .home-content {
    padding: clamp(20vh, 40vh, 25vh) 0 0 0;

  }

  .title {
    font-size: clamp(6vh, 135px, 8vw);
  }

  .about-title {
    font-size: clamp(3vw, 70px, 6vw) !important;

  }

}

@media (min-width: 767px) and (max-width: 992px) {
  .about-img {
    padding: clamp(5vw, 18vw, 17vw) 0 0 0;
  }

  .logo img {
    display: none !important;

  }

}


@media (max-width: 1199px) {

  .myAvatar {
    display: none;
  }
}

@media (min-width: 1200px) {
  .myAvatar {
    padding: clamp(11rem, 17rem, 16rem) 0 0 0 !important;
  }

  .myAvatar img {
    width: 50rem;
  }

}


@media (min-width: 992px) {

  .home-title {
    padding: 8vh 0 0 0;
  }

  .logo img {
    height: auto !important;
    width: clamp(8vw, 100px, 8vw) !important;
  }


  // .about-title {
  //   font-size:  clamp(4vw, 9vw, 65px) !important;
  // }

}


@media (min-width: 1400px) {

  .minecraft {
    font-size: 7vw !important;
  }

  .welcome {
    padding-left: 100px;
  }

  .project-heading {
    font-size: clamp(2vw, 5vw, 2.5vw) !important;
  }

  .home-img {
    padding: 0;
  }


}


home-2-container {
  flex-direction: row-reverse !important;
  flex-wrap: nowrap !important;

}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
  flex-direction: row-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: justify;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #187a18;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #25951f;
}

.home-social-icons:hover {
  color: #209e2b;
  box-shadow: 0 0 5px #209e33;
  text-shadow: 0 0 2px #209e22;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #10860c !important;
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-color: #000000;
  // background-image: var(--section-background-color) !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(60, 136, 53, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(77, 144, 72, 0.561) !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(81, 161, 77, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(96, 173, 88, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #418636 !important;
  border-color: #368641 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #41c520d7 !important;
  border-color: #20c52ed7 !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

.footer {
  background-color: rgb(4, 22, 4);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

.footer-copywright {
  text-align: center !important;
}

.footer-body {
  text-align: center !important;
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #88c08c !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #000000;
  background-image: url(./assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #19501b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(121, 216, 121, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(130, 235, 142, 0.911);
  border-radius: 12px;
}